/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import { ReactTypeformEmbed } from "react-typeform-embed";

import Layout from "../components/Layout";
import { typography, mq, colors } from "../components/styles";
import { useTranslation } from "../components/translation";

const _strings = {
  en: {
    caption: "Leave us your details and we'll get back to you promptly.",
    header: "Request a personalized demo",
    typeformLink: "https://ethanohayon.typeform.com/to/W65nieu9",
  },
  fr: {
    caption: "Transmettez-nous vos informations et nous vous rappellerons.",
    header: "Demandez une démonstration personnalisée",
    typeformLink: "https://ethanohayon.typeform.com/to/mUX4Bv3V",
  },
};

const Demo = ({ location }) => {
  const { strings } = useTranslation(_strings);
  return (
    <Layout location={location}>
      <div
        css={{
          flex: 1,
          padding: "0 1rem",
          [mq[1]]: {
            padding: "0 11vw",
          },
          [mq[2]]: {
            display: "flex",
          },
        }}
      >
        <div
          css={{
            margin: "0 0 1rem",
            [mq[2]]: {
              margin: "0 2rem 0 0",
            },
          }}
        >
          <h1
            css={{
              ...typography.serif,
              fontSize: "1.8rem",
              margin: "0 0 1rem",
            }}
          >
            {strings.header}
          </h1>
          <h2
            css={{
              ...typography.sansSerif,
              fontSize: "1.4rem",
              fontWeight: "normal",
              margin: "0",
            }}
          >
            {strings.caption}
          </h2>
        </div>
        <div
          css={{
            backgroundColor: colors.pastel,
            borderRadius: "0.4rem",
            boxShadow:
              "-18px -18px 36px #F3F3F3, 18px 18px 36px rgba(119, 55, 170, 0.1), inset 4px 4px 18px rgba(236, 236, 236, 0.5)",
            overflow: "hidden",
            [mq[2]]: {
              width: "50%",
            },
          }}
        >
          <ReactTypeformEmbed
            style={{
              height: "500px",
              maxHeight: "100vh",
              position: "relative",
              width: "100%",
            }}
            url={strings.typeformLink}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Demo;
